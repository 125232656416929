#popup-modal {
  .uk-container {
    width: 100vw;
  }

  .uk-modal-body {
    padding: 100px 100px;
  }

  .popup {
    &--title {
      margin-bottom: 30px;
      margin-top: 20px;
    }

    &--image {
      display: block;
      position: absolute;
      width: 45%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      left: 0;
      top: 0;
    }

    &--body {
      position: relative;
      margin-left: 45%;
    }

    &--footer {
      background-color: transparent;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  #popup-modal {
    .uk-modal-body {
      padding: 50px 50px;
    }
  }
}

// Mobile devices
@media (max-width: 640px) {
  #popup-modal {
    .uk-container {
      width: 100vw;
    }

    .uk-modal-body {
      margin-top: calc(50% + 55px);
      padding: 20px 20px;
    }

    .popup {
      &--image {
        display: block;
        position: absolute;
        width: 100%;
        height: 40%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        left: 0;
        top: 0;
      }

      &--body {
        position: relative;
        margin-left: 0;
      }

      &--footer {
        background-color: transparent;
      }
    }
  }

}