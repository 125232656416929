// .main-navbar {
//   display: grid;
//   grid-template-columns: 1fr 2fr 1.5fr;
//   gap: 20px 20px;
//   padding: 25px 0;
//   align-content: center;

//   &__left {
//     .uk-logo {
//       display: block;
//     }

//     img {
//       max-width: 100%;
//     }
//   }

//   &__center {
//     display: flex;
//     align-items: center;
//   }

//   &__right {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;

//     >a,
//     >.dropdown-item>a {
//       display: flex;
//       flex-flow: column;
//       justify-content: center;
//       align-items: center;
//       color: $black;
//       margin-right: 30px;

//       span {
//         margin-top: 10px;
//         font-weight: normal;
//         font-size: 16px;
//       }

//       &:hover {
//         color: $primary;
//         text-decoration: none;
//       }

//       &:last-child {
//         margin-right: 0;
//       }

//       &.cart-button {
//         position: relative;

//         .amount {
//           position: absolute;
//           top: -7px;
//           left: calc(50% + 3px);
//           width: 18px;
//           height: 18px;
//           background-color: $primary;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           color: $white;
//           margin: 0;
//           z-index: 9;
//           border-radius: 9999px;

//           font-weight: bold;
//           font-size: 12px;
//           text-align: center;
//         }
//       }
//     }
//   }
// }

header {
  padding: 25px 0;
  width: 100%;
  z-index: 200;

  li::before {
    content: none !important;
  }

  &.header-home {
    position: absolute;
    // border-bottom: 1px solid rgba(238, 242, 245, 0.16);

    .main-nav__top li a,
    .main-nav__bottom li a {
      color: $white !important;

      &.uk-button-primary {
        color: $secondary !important;

      }

      &.uk-active:hover,
      &:hover {
        color: $secondary;
      }
    }

    .uk-navbar-toggle {
      fill: $white;
    }
  }

  .uk-navbar-toggle {
    fill: $secondary;
  }

  .main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .uk-form-icon {
      color: $white;

      &-flip {
        right: 10px;
      }
    }

    .uk-input {
      background-color: $grey-light;
      color: $white;
      border-radius: 50px;
      padding: 0 20px;

      &::placeholder {
        color: $white;
      }
    }

    .uk-dropdown {
      background-color: $white;
      border-radius: 15px;
      min-width: auto;

      li {
        margin: 0;
      }
    }

    &__logo {
      width: 220px;

      img {
        width: 100%;
      }
    }

    &__right {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-end;
      height: 110px;

      ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }

    &__dropdown {
      flex-direction: column;
      align-items: flex-start !important;
    }

    &__top {
      li {
        margin: 0px 15px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
          margin-left: 15px;
        }

        a {
          font-size: 16px;
          line-height: 24px;
          color: $secondary;
          transition: all .2s;

          &:hover {
            color: $primary;
            text-decoration: none;
          }

          &.uk-active {
            text-decoration: none;
            color: $secondary;
            font-weight: bold;

            &:hover {
              color: $primary;
            }
          }
        }
      }

      .uk-active {
        color: $secondary;
        font-weight: bold;
        text-decoration: underline;
        text-underline-offset: 15px;
        text-decoration-color: $primary;
        text-decoration-thickness: 4px;
      }

      .c-main-navbar__dropdown {
        -webkit-box-shadow: 0px 4px 5px 3px rgba(0, 0, 0, 0.06);
        -moz-box-shadow: 0px 4px 5px 3px rgba(0, 0, 0, 0.06);
        box-shadow: 0px 4px 5px 3px rgba(0, 0, 0, 0.06);
        left: -15px !important;

        li {
          margin-left: 0;
          padding-right: 15px;

          a {
            line-height: 10px;
            color: $secondary;
          }
        }
      }
    }

    &__bottom {
      margin-top: 30px !important;

      li {
        margin: 0px 15px;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        a {
          font-size: 18px;
          line-height: 27px;
          color: $secondary;
          transition: all .2s;

          &.uk-active {
            &:hover {
              font-weight: bold;
              text-decoration: underline !important;
              text-underline-offset: 35px;
              text-decoration-color: $primary !important;
              text-decoration-thickness: 4px !important;
              color: $primary !important;
            }
          }

          &.uk-button-primary {
            &.uk-active {
              background-color: $primary !important;
              text-decoration: none;

              &:hover {
                background-color: darken($color: $primary, $amount: 20%);
                color: $white !important;
              }
            }

            &:hover {
              background-color: darken($color: $primary, $amount: 20%);
              color: $white !important;
            }
          }

          &:hover {
            text-decoration: none;
            color: $primary !important;
          }
        }
      }

      .uk-active {
        color: $secondary;
        font-weight: bold;
        text-decoration: underline;
        text-underline-offset: 42px;
        text-decoration-color: $primary;
        text-decoration-thickness: 4px;
      }
    }


  }
}



.mobile-menu {
  .uk-offcanvas-bar {
    width: 50vw;
    right: -50vw;
    padding: 0;
    transition: right 0.5s ease;
  }

  &.uk-open {
    .uk-offcanvas-bar {
      right: 0;
    }
  }

  &__header {
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      width: 50px;
      height: 50px;
      background-color: $primary;
      color: $white;
      border: 0;

      font-size: 21px;
    }
  }

  .uk-nav {
    >li {
      >a {
        border-bottom: 1px solid $grey;
        padding: 12px 12px 12px 20px;
      }
    }
  }

  .uk-nav-sub {
    padding: 0;

    li {
      a {
        border-bottom: 1px solid $grey;
        padding: 8px 4px 8px 35px;
      }
    }

    .uk-active {
      color: $primary;
    }
  }

  .uk-active {
    color: $primary;
  }
}

.uk-offcanvas {
  right: 0;
  left: auto;
  transition: background-color .3s ease-in-out;
  background-color: transparent;
  width: 100%;
  height: 100%;

  &.uk-open {
    background-color: rgba($color: #000000, $alpha: .5);
    transition: background-color .3s ease-in-out;
  }
}

.uk-offcanvas-bar {
  left: auto;
  right: -270px;
}

@media (min-width: 960px) {
  .uk-offcanvas-bar {
    left: auto;
    right: -450px;
    width: 450px;
  }
}

.uk-open>.uk-offcanvas-bar {
  right: 0;
  left: auto;
  padding: 60px;

  .form-custom {
    padding: 0;
    box-shadow: none;
    margin-top: 45px;
  }
}

.uk-offcanvas-bar-animation {
  transition: right 0.3s ease-out;
}



// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {

  .uk-navbar-nav>li>a,
  .uk-navbar-item,
  .uk-navbar-toggle {
    min-height: 0;
  }
}

// Mobile devices
@media (max-width: 640px) {
  .uk-offcanvas-bar {
    width: 100vw;
    left: auto;
    right: -100vw;
  }
}