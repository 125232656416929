/* iframe videos
-------------------------------------------------- */

.vimeo-iframe,
.youtube-iframe,
.other-iframe {
  padding-bottom: 56.67%;
  position: relative;
  width: 100%;
}

.video-iframe {
  height: 100%;
  left: 0;
  position: absolute;
  width: 100% !important;
}

// ==========[ WYSIWYG / PAGE BUILDER ]==========

.full-left-image,
.full-right-image {
  position: absolute;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.full-left-image {
  right: 50%;
  left: 0;
}

.full-right-image {
  right: 0;
  left: 50%;
}

// ==========[ IMAGE BLOCKS ]==========

.image-block {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.square {
    padding-top: 100%;
  }

  &.long {
    padding-top: 133%;
  }

  &--half {
    padding-top: 56%;
  }

  &.contain {
    background-size: contain;
  }
}

.uk-dotnav> {
  li {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    &::before {
      background-image: none;
    }
  }

  *>a {
    background-color: transparent;
    border: 1px solid $lines;
    width: 10px;
    height: 10px;

    &:hover {
      background-color: $primary;
    }
  }

  .uk-active>* {
    width: 14px;
    height: 14px;
    background-color: $primary;
    border: 1px solid $primary;
  }
}

.uk-slideshow-nav {
  height: 14px;
}

.uk-slideshow-items.images_block {
  padding-bottom: 66.6%;

  li,
  li>div {
    padding-bottom: 66.6%;
  }
}

.images_block_text {
  margin-bottom: 54px !important;
}

// ==========[ TRUMPS ]==========
.trumps {
  .trump {
    margin-top: 60px;

    img {
      height: 60px;
    }
  }
}

// ==========[ REVIEWS ]==========
.reviews {
  margin-top: 60px;

  .review {
    background-color: $white;
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.3);
    padding: 40px;
    border-radius: 6px;

    &-person {
      margin-top: 30px;

      &__image {
        width: 60px;
        height: 60px;
        border-radius: 50px;
        margin-right: 15px;
      }

      &__name {
        font-family: $dunbar;
        color: $secondary;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      &__place {
        font-family: $circe;
        color: $secondary;
        font-size: 16px;
      }
    }
  }

  .uk-slider-container {
    overflow: visible;
    overflow-x: clip;

    li::before {
      content: none !important;
    }
  }

  .uk-slider-nav {
    margin-top: 60px;
    height: 14px;
  }
}

// ==========[ TEAM ]==========
.uk-tab {
  justify-content: center;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  padding: 0;

  li>a {
    color: $secondary;
    font-family: $circe;
    transition: 0s;

    &:hover {
      color: darken($color: $secondary, $amount: 40%);
    }
  }

  .uk-active>a {
    color: $secondary;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: $primary;
    text-underline-offset: 10px;
    text-decoration-thickness: 3px;

    &:hover {
      color: $secondary;
    }
  }
}

.uk-switcher {
  margin-top: 60px;
}

.team {
  margin-bottom: 60px;
  margin-top: 60px;

  &-person {
    &__image {
      position: relative;

      &-org,
      &-active {
        padding-bottom: 150%;
        transition: opacity .4s ease-in-out !important;
      }

      &-active {
        opacity: 0 !important;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      &:hover {
        .team-person__image-org {
          opacity: 0 !important;
        }

        .team-person__image-active {
          opacity: 1 !important;
        }
      }
    }

    &__name,
    &__function,
    &__contact>* {
      color: $secondary;
      font-family: $circe;
    }

    &__name {
      font-weight: bold;
      font-size: 18px;
      margin-top: 40px;
      margin-bottom: 5px;
    }

    &__contact {
      margin-top: 20px;

      &>* {
        font-weight: bold;
      }
    }
  }

  &-head {
    .uk-slideshow-items {
      padding-bottom: 100%;

      li {
        padding-bottom: 100%;

        div {
          padding-bottom: 100%;
          background-size: contain;
        }
      }
    }
  }
}


/* Modals
-------------------------------------------------- */

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($color: #e7f8f8, $amount: 0.05);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  overflow-y: scroll;

  &--big {
    align-items: flex-start;
  }

  &__box {
    width: 100%;
    max-width: 1050px;
    background-color: $white;
    border-radius: 3px;
    box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
    padding: 45px 60px;

    &--image {
      position: relative;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 66.66%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__content {
    padding-left: calc(33.33% + 50px);
  }
}

/* Category blocks
-------------------------------------------------- */
.block-link-category-wrapper {
  text-align: center;

  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: $black;
  }

  &:hover {
    text-decoration: none;

    span {
      color: $primary;
      text-decoration: none !important;
    }

    .block-link-category {
      background-color: $primary;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }

    .block-image-link-category {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }
  }
}

.block-link-category {
  position: relative;
  background-color: $secondary;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  span {
    position: absolute;
    left: 25px;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);

    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: $black;
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: $primary;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    span {
      color: $white;
    }
  }
}

.block-image-link-category {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 75%;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.3s ease;
  }

  span {
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: 25px;

    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: $white;
    transition: color 0.3s ease;
  }

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

    .overlay {
      background-color: transparentize($color: $primary, $amount: 0.3);
    }
  }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  .reviews {
    .uk-grid {
      margin-left: -30px;

      &>* {
        padding-left: 30px;
      }
    }

    .review {
      padding: 25px;

      .big>p {
        font-size: 16px;
      }
    }
  }
}

// Mobile devices
@media (max-width: 640px) {}