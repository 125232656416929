.uk-pagination {
  li {
    padding-left: 5px;

    &::before {
      content: none;
    }

    >* {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $grey;
      border-radius: 3px;

      font-weight: 300;
      font-size: 16px;
      line-height: 30px;
      color: $black;
    }

    >a {
      &:hover {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
      }
    }

    &.uk-active {
      >* {
        font-weight: bold;
        background-color: $primary;
        color: $white;
        border: 1px solid $primary;
      }
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
  .uk-pagination {
    li {
      >* {
        width: 35px;
        height: 35px;
      }
    }
  }
}