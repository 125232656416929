/* Fonts
-------------------------------------------------- */

$ff-stack: 'sofia-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';

$houschka: houschka-rounded, sans-serif;
$dunbar: dunbar-tall, sans-serif;
$circe: circe-rounded, sans-serif;

/* Colors
-------------------------------------------------- */
$white: #ffffff;
$grey-light: #f8f8f8;
$grey: #e8e8e8;
$grey-dark: #666666;
$grey-disabled: #b7b2b2;
$black: #222222;

$primary: #FFAC2D;
$secondary: #0D3F5E;

$bg: #EEF2F5;

$text: $black;
$border: $grey;
$lines: #D5DEE3;

$danger-color: #ff2424;
$danger-color-light: lighten($danger-color, 70%);
$warning-color: #ff6601;
$success-color: #1eaf3a;
$good-color: #D0D201;

$cookie-button-color: $white;
$cookie-button-bgcolor: $primary;
$cookie-link-color: $black;

/* Border radius
-------------------------------------------------- */
$border-radius: 3px;

/* Shadows
-------------------------------------------------- */
$shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
$shadow2: 0px 5px 15px rgba(0, 0, 0, 0.2);

/* Mixin's
-------------------------------------------------- */
@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin fix-bttn {
  display: inline-block;
  text-align: center;

  &:after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}


/* Functions
-------------------------------------------------- */