.uk-link {
    position: relative;
    padding-right: 20px;

    span {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: $primary;
        transition: color 0.3s ease;
    }

    i,
    svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.4s ease, color 0.3s ease;
        color: $primary;
        font-size: 14px;
    }

    &:hover {
        text-decoration: none;

        span,
        i,
        svg {
            color: darken($color: $primary, $amount: 6%);
        }

        i,
        svg {
            transform: translateX(6px) translateY(-50%);
        }
    }

    &--black {

        span,
        i,
        svg {
            color: $black;
        }
    }
}

.uk-button {
    font-weight: bold;
    font-family: $dunbar;
    font-size: 14px !important;
    line-height: 21px !important;
    padding: 20px 40px;
    border-radius: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    transition: all .3s;
    letter-spacing: 2px;

}

.uk-button-primary {
    background-color: $primary;
    color: $secondary;

    &:hover {
        background-color: darken($color: $primary, $amount: 20%);
        color: $secondary !important;
    }


}

.uk-button-secondary {
    border: 2px solid $secondary;
    padding: 18px 38px;
    color: $secondary;
    background-color: transparent;

    &:hover {
        background-color: $secondary;
        color: $white !important;
    }
}

.uk-button-tertiary {
    color: $secondary;
    padding: 0 10px;

    &:hover {
        color: $secondary !important;
    }
}

.button-icon {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    color: $white;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &--large {
        width: 120px;
        height: 120px;
        font-size: 21px;
    }

    &:hover {
        background-color: $secondary;
        color: $primary;
        box-shadow: 0px 10px 30px -10px rgba(0, 0, 0, 0.3);
    }
}

.housemap-button {
    font-family: $circe;
    font-size: 18px;
    line-height: 27px;
    color: $secondary;
    display: flex;
    align-items: center;
    font-weight: 600;
}

.block-link {
    position: relative;
    background-color: $secondary;
    display: block;
    width: 100%;
    padding-top: 75%;
    border-radius: $border-radius;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    span {
        position: absolute;
        left: 25px;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);

        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $black;
        transition: color 0.3s ease;
    }

    &:hover {
        background-color: $primary;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

        span {
            color: $white;
        }
    }
}

.block-image-link {
    position: relative;
    background-color: $secondary;
    display: block;
    width: 100%;
    padding-top: 75%;
    border-radius: $border-radius;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparentize($color: $black, $amount: 0.7);
        transition: background-color 0.3s ease;
    }

    span {
        position: absolute;
        left: 25px;
        right: 25px;
        bottom: 25px;

        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: $white;
        transition: color 0.3s ease;
    }

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);

        .overlay {
            background-color: transparentize($color: $primary, $amount: 0.3);
        }
    }
}

.uk-lightbox-button {
    background-color: transparent;
    margin-left: 0 !important;
    margin-right: 0 !important;
    color: $primary !important;

    &:hover,
    &:active,
    &:focus {
        color: $primary !important;
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
    .uk-button {
        padding: 15px 20px;

        &-secondary {
            padding: 10px 20px;
        }
    }
}

// Mobile devices
@media (max-width: 640px) {}