/* General
-------------------------------------------------- */

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.body-bg {
  width: 100vw;
  height: 100vh;
  z-index: -9999999;
  background-image: url('/dist/assets/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.label_matrix {
  text-align: right;
  font-size: 15px;
  font-style: italic;
  padding: 5px;
  position: absolute;
  right: 15px;
  z-index: 999;
}

.uk-container {
  max-width: 1200px;
}

// ==========[ LAZYSIZES ]==========

// lazysizes
.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.2s ease;
}

.lazyload-wrapper {
  position: relative;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

// TER INFO: bijbehorende HTML:
// Voor img tag:
// <div class="lazyload-wrapper" style="padding-bottom: <?= getImageRatio($page->image) ?>%;">
//   <img data-src="<?= $page->image->size(1600, 1600, ['cropping' => false])->url ?>" alt="<?= $page->image->description ?>" class="lazyload">
// </div>
// Voor backgroundimage:
// <div class="lazyload" data-bgset="<?= $page->image->size(2400, 2400, ['cropping' => false])->url ?>"></div>


/* Product detail page
-------------------------------------------------- */

.cart-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.uk-section {
  &-muted {
    background-color: $bg;

    &-half {
      background: linear-gradient(to bottom, $bg 52%, $white 52%);
    }
  }

  &-medium {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.rounded {
  border-radius: 6px;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-top: 60px;

  li {
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;



    a {
      font-family: $circe;
      font-weight: bold;
      transition: .2s;

      span {
        height: 20px;
        display: flex;
      }
    }

    &:hover {
      a {
        color: $primary;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: none !important;
    }



    &.MarkupPagerNavPrevious,
    &.MarkupPagerNavNext {
      padding: 7px 10px;
      width: 30px;
      height: 30px;
      color: $secondary;
      background-color: $primary;
      border-radius: 50px;
      transition: .2s;

      &:hover {
        background-color: darken($color: $primary, $amount: 20%);
        cursor: pointer;

        i {
          color: $secondary;
        }
      }

      a {
        line-height: 0;

        span {
          height: auto;
        }


      }

      i {
        font-size: 16px;
      }
    }

    &.MarkupPagerNavOn {
      a {
        color: $primary;
      }
    }
  }
}



.background {
  &-primary {
    background-color: $primary;
  }

  &-secondary {
    background-color: $secondary;
  }
}


.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &-contain {
    background-size: contain;
  }

  &-nosize {
    background-size: auto;
  }
}

.home-banner {
  &-container {
    height: 100vh;
    width: 100vw;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      display: flex;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, .08);
    }
  }

  &-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // z-index: 0;
    display: flex;
    align-items: center;
  }

  &-video {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // z-index: 0;
    display: flex;
    align-items: center;
    object-fit: cover;
  }

  &-content {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 20;


    h1 {
      color: $white;
    }

    &-buttons {
      margin-top: 30px;

      .button {
        &-left {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-right: 1px solid $secondary;
        }

        &-right {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
      }
    }
  }
}

.page-banner {
  height: 37vh;
  max-height: 400px;
  position: relative;
}

.banner-triangle {
  position: absolute;
  right: 0;
  bottom: -0.5px;
}

// CONTACTPAGE


.contact {
  &-details {
    i {
      color: $secondary;
    }

    a {
      font-weight: bold;

      &:hover {
        color: $primary;
      }
    }

    a,
    p,
    i {
      font-size: 18px;
    }

    &-container {
      padding-bottom: 150px;
    }
  }

  &-form {
    z-index: 5;
  }
}


.map {
  margin-top: -80px;
  z-index: 0;
  height: 500px;
}

.uk-switcher,
.uk-tab {
  li::before {
    content: none !important;
  }
}

.contact-component {

  .col-md-1,
  .col-md-2,
  .col-md-5,
  .col-md-6,
  .col-md-8,
  .col-sm-1,
  .col-sm-2,
  .col-sm-5,
  .col-sm-6,
  .col-sm-8 {
    width: 100%;
    margin-top: 30px;
  }

  input,
  textarea,
  select {
    width: 100%;

  }

  input,
  textarea {
    height: 50px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid $lines;
    border-radius: 0;
    padding: 0px;

    font-family: $circe;
    font-size: 18px;
    line-height: 27px;
    color: $secondary;

    &:focus {
      border-bottom: 1px solid $primary;
      outline: none;
      background-color: transparent;
      color: $secondary;
    }
  }

  textarea {
    height: 125px;
  }

  select {
    padding: 0px;
    height: 50px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $lines;
    font-family: $circe;
    font-size: 18px;
    line-height: 27px;
    color: $secondary;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: url(/dist/assets/images/icons/angle-down.svg) no-repeat 100% transparent;
    background-position: right 0px center;

    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
      border-bottom: 1px solid $primary;
    }
  }

  .btn-primary {
    font-weight: bold;
    font-family: $dunbar;
    font-size: 14px !important;
    line-height: 21px !important;
    padding: 20px 40px;
    border: none;
    border-radius: 50px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    transition: all .3s;
    letter-spacing: 2px;
    background-color: $primary;
    color: $secondary;

    &:hover {
      background-color: darken($color: $primary, $amount: 20%);
      color: $secondary;
    }
  }
}



.faq {
  margin-bottom: 60px;

  &-item {
    margin-top: 30px;

  }

  li {
    padding-bottom: 20px;
    border-bottom: 1px solid $lines;

    &::before {
      content: none !important;
    }

    .uk-accordion-title {
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      color: $secondary;
      display: flex;
      align-items: center;
      transition: .2s;

      i {
        font-size: 16px;
        height: 100%;
        transition: .2s;
      }
    }

    .uk-accordion-content {
      font-size: 16px;
      width: 80%;
    }

    &.uk-open {
      .uk-accordion-title {
        font-weight: bolder;

        &>i {
          transform: rotate(180deg);
        }
      }
    }
  }
}



// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  .home-banner-container {
    height: 75vh;
  }

  .page-banner {
    max-height: 250px;
  }

  .contact-details {

    a,
    p,
    i {
      font-size: 16px;
    }

    &-container {
      padding-bottom: 40px;
    }
  }

  .uk-section {
    &-medium {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .video--form {
    &--contact {
      .form-custom {
        padding: 40px;
      }
    }
  }
}

// Mobile devices
@media (max-width: 640px) {
  .page-banner {
    max-height: 200px;
  }

  .uk-section {
    &-medium {
      padding-top: 0;
      padding-bottom: 40px;
    }
  }

  .home-banner-content-buttons {
    flex-direction: column;

    a:last-of-type {
      margin-top: 10px;
    }

    .button-left {
      border-right: none;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
    }

    .button-right {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }
  }
}

.ig-logo svg{
	fill: #000;
	transition: fill 0.2s ease;
	width: 10px;
}

.ig-logo:hover svg{
	fill:#e90100;
}
