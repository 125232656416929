.mobile-menu {
  .uk-offcanvas-bar {
    width: 100vw;
    right: -100vw;
    padding: 0;
    position: absolute;
    overflow-y: scroll;
    transition: right 0.6s ease;
    background-color: $secondary;

    ul {
      padding: 20px 30px;

      .uk-button-primary {
        color: $secondary;
        border-bottom: none;
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }

  &.uk-open {
    .uk-offcanvas-bar {
      right: 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    padding: 25px 5px;
    height: 75px;

    .uk-svg {
      color: $white;
    }

    .uk-offcanvas-close {
      position: relative;
      top: auto;
      right: auto;
      background-color: transparent;
      color: $white;
      border: 0;
      font-size: 21px;
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;

    >li {
      margin-bottom: 0;

      a {
        color: $white !important;
        padding-left: 0 !important;
      }

      &.uk-active {
        a {
          font-weight: bold;
          color: $white !important;
        }
      }

      // &:nth-child(odd) {

      //   a,
      //   section {
      //     background-color: $secondary;

      //     &.mobile-category-nav-item {
      //       .arrow {
      //         &__previous {
      //           border-color: $white;
      //         }

      //         &__next {
      //           border-color: $white;
      //         }
      //       }
      //     }
      //   }
      // }

      // &:nth-child(even) {
      //   section {
      //     &.mobile-category-nav-item {
      //       .arrow {
      //         &__previous {
      //           border-color: $secondary;
      //         }

      //         &__next {
      //           border-color: $secondary;
      //         }
      //       }
      //     }
      //   }
      // }

      .mobile-category-nav-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        color: $black;

        .arrow {
          padding: 10px 35px;

          &__previous {
            border-right: 1px solid $secondary;
          }

          &__next {
            border-left: 1px solid $secondary;
          }

          i,
          svg {
            color: #909090;
          }
        }

        a {
          padding: 10px 35px;
          width: 100%
        }
      }

      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 35px;
        font-size: 20px;
        line-height: 30px;

        section:last-of-type {

          i,
          svg {
            color: #909090;
          }
        }

        .uk-svg {
          color: $black !important;
        }
      }
    }
  }

  &__accordion {
    margin: 0;

    li {
      margin: 0 !important;

      a.uk-accordion-title {
        padding: 14px 35px;
        font-size: 16px;
        line-height: 24px;
        color: $black;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .uk-accordion-content {
        margin: 0;
        padding: 20px 35px;
      }

      &:nth-child(odd) {
        a.uk-accordion-title {
          background-color: $grey-light;
        }
      }

      &.uk-open {
        a {

          i,
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__apply {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $secondary;
    padding: 10px;
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {}