/*========================================================
=                       Typography                        =
=========================================================*/
h1,
.uk-h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: $secondary;
  font-family: $houschka;
}

h2,
.uk-h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 38.4px;
  color: $secondary;
  font-family: $houschka;
}

h3,
.uk-h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 28.8px;
  color: $secondary;
  font-family: $houschka;
}

h4,
.uk-h4 {
  font-size: 18px;
  line-height: 21.6px;
  color: $secondary;
  font-family: $houschka;
}

.display {
  font-size: 40px;
  line-height: 48px;
  color: $secondary;
}

.tag {
  font-size: 14px;
  line-height: 21px;
  color: $primary;
  font-family: $dunbar;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}

a {
  font-family: $circe;
  color: $secondary !important;
  transition: .2s;

  &:hover {
    text-decoration: none !important;
    color: darken($color: $secondary, $amount: 50%) !important;
  }
}

p.big,
*.big>p {
  font-size: 18px;
  line-height: 27px;
}

p.small,
*.small>p {
  font-size: 14px;
  line-height: 21px;
}

p,
.cms,
.text {
  font-size: 16px;
  line-height: 24px;
  color: $secondary;
  font-family: $circe;

  em {
    color: $text;
  }

  a {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: darken($color: $primary, $amount: 8%);
    }
  }

  table {
    border: 1px solid $black;
    border-collapse: collapse;

    th,
    td {
      padding: 8px 15px;
    }
  }

  // size
  &--big {
    font-size: 18px;
    line-height: 27px;

    p {
      font-size: 18px;
      line-height: 27px;
    }
  }

  &--small {
    font-size: 14px;
    line-height: 21px;

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &--tiny {
    font-size: 11px;

    p {
      font-size: 11px;
    }
  }

  // color
  &--white {
    color: $white;

    p {
      color: $white;
    }
  }

  &--grey {
    color: $grey;

    p {
      color: $grey;
    }
  }

  &--grey-dark {
    color: $grey-dark;

    p {
      color: $grey-dark;
    }
  }

  &--black {
    color: $black;

    p {
      color: $black;
    }
  }

  &--primary {
    color: $primary;

    p {
      color: $primary;
    }
  }

  &--success {
    color: $success-color;

    p {
      color: $success-color;
    }
  }

  &--warning {
    color: $warning-color;

    p {
      color: $warning-color;
    }
  }

  &--danger {
    color: $danger-color;

    p {
      color: $danger-color;
    }
  }

  // alingment
  &--center {
    text-align: center;

    h1,
    .uk-h1,
    h2,
    .uk-h2,
    h3,
    .uk-h3,
    h4,
    .uk-h4,
    h5,
    .uk-h5,
    p,
    .cms,
    .text {
      text-align: center;
    }
  }

  &--right {
    text-align: right;

    h1,
    .uk-h1,
    h2,
    .uk-h2,
    h3,
    .uk-h3,
    h4,
    .uk-h4,
    h5,
    .uk-h5,
    p,
    .cms,
    .text {
      text-align: right;
    }
  }
}

p {
  margin-bottom: 0;

  &+p {
    margin-top: 24px;
  }
}

.no-hover {
  &:hover {
    text-decoration: none;
  }
}

.input-errors {
  p {
    color: $danger-color;
    font-size: 14px;
    margin-top: 5px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

sub,
sup {
  font-size: 100%;
}

address {
  font-size: 16px;
  line-height: 24px;
  color: $text;

  a {
    color: $primary;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: darken($primary, 8%);
    }
  }
}

// general lists
ol,
ul {
  padding-left: 25px;

  li {
    font-size: 16px;
    line-height: 24px;
    color: $text;
    list-style: none;
    position: relative;
    margin-bottom: 15px;
    color: $secondary;
    font-family: $circe;

    &::marker {
      color: $primary;
      font-size: 20px;
    }

    &::before {
      position: absolute;
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url(/dist/assets/images/icons/check.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {

  h1,
  .uk-h1 {
    font-size: 32px;
    line-height: 38.4px;
  }

  h2,
  .uk-h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 28.8px;
    color: $secondary;
    font-family: $houschka;
  }

  h3,
  .uk-h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21.6px;
    color: $secondary;
    font-family: $houschka;
  }

  h4,
  .uk-h4 {
    font-size: 18px;
    line-height: 21.6px;
    color: $secondary;
    font-family: $houschka;
  }

  .display {
    font-size: 32px;
    line-height: 38.4px;
    color: $secondary;
  }

  .tag {
    font-size: 12px;
    line-height: 21px;
  }

  p.big,
  *.big>p {
    font-size: 16px;
    line-height: 24px;
  }
}

// Mobile devices
@media (max-width: 640px) {}