.blog {
    margin-top: 60px;

    &:nth-child(-n + 2) {
        margin-top: 0;
    }

    &__image {
        margin-bottom: 40px;
    }

    .tag {
        margin-bottom: 20px;
    }

    .uk-button-secondary {
        width: fit-content;
        margin-top: 20px;
    }

    &-detail {
        &__image {
            margin-bottom: 60px !important;
        }
    }
}

// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
    .blog {
        &__image {
            margin-bottom: 20px;
        }

        .tag {
            margin-bottom: 10px;
        }

        &-detail__image {
            margin-bottom: 30px !important;
        }
    }
}

// Mobile devices
@media (max-width: 640px) {}