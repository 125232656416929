.products-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $border;
    margin-top: 40px;

    .custom-select {
        margin-bottom: -1px;
        border-radius: 0;
        background: url(/dist/assets/images/icons/angle-down.svg) no-repeat 100% transparent;
        // border: none;
    }

    ul>li {
        &::before {
            content: none !important;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
    .products-header {
        flex-flow: column;
        align-items: stretch;
        border-bottom: 0;
    }
}

// Mobile devices
@media (max-width: 640px) {}