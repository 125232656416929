.top-banner {
  background-color: $primary;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 999;

  &--title {
    color: $secondary;
    font-weight: bold;
  }

  &--text {
    color: $secondary;

    p,
    a {
      color: $secondary;
    }

    a:hover {
      color: darken($color: $secondary, $amount: 40%);
      text-decoration: underline;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  &--close {
    color: $secondary;
    font-size: 18px;
    transition: .2s;

    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  .top-banner {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

// Mobile devices
@media (max-width: 640px) {}