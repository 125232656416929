.input-errors {
    p {
        color: $danger-color;
        margin-bottom: 0px;
        margin-top: 0px;
    }
}

.custom-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    color: $primary;

    input[type='checkbox'] {
        width: 20px;
        height: 20px;
        background-color: $white;
        border: 1px solid $border;
        box-shadow: none;
        flex: 0 0 20px;
        margin-right: 10px;
        margin-top: 0px;
        margin-bottom: 0px;

        &:checked {
            border: 1px solid $primary;
            background-color: $primary;
            background-image: url("/dist/assets/images/icons/check.svg");
            background-size: 12px 10px;
        }

        &[disabled] {
            background-color: $grey-disabled;
            border: 1px solid $grey-disabled;
        }
    }

    div {
        flex: 1 0 auto;
    }

    a {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: $primary;
        text-decoration: underline;
        display: initial;
        color: $secondary;

        &:hover,
        &:active,
        &:focus {
            color: darken($primary, 8%);
        }
    }

    &.uk-form-danger {
        color: $danger-color;

        input[type='checkbox'] {
            border: 2px solid $danger-color;
        }
    }

    &.disabled {
        p {
            color: $grey-disabled;
        }

        &:hover {
            cursor: no-drop;
        }

        input[type='checkbox'] {
            &:hover {
                cursor: no-drop;
            }
        }
    }

    &--big {
        input[type='checkbox'] {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;

            &:checked {
                background-image: url("/dist/assets/images/icons/check.svg");
                background-size: 24px 20px;
            }
        }
    }
}

.custom-radio {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;

    input[type='radio'] {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
        margin-right: 10px;
        margin-top: 2px;

        background-color: transparent;
        border: 1px solid $border;

        &:checked {
            border: 1px solid $primary;
            background-color: $primary;
            background-size: 150%;
        }
    }
}

.uk-input,
.uk-textarea {
    height: 50px;
    background-color: transparent;
    border-bottom: 1px solid $lines;
    border-radius: 0;
    padding: 0px;

    font-family: $circe;
    font-size: 18px;
    line-height: 27px;
    color: $secondary;

    &:focus {
        border-bottom: 1px solid $primary;
        outline: none;
        background-color: transparent;
        color: $secondary;
    }

    &.uk-form-danger {
        border-bottom: 1px solid $danger-color;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $danger-color;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $danger-color;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $danger-color;
        }
    }

    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #798981;
        opacity: 1;
        /* Firefox */
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #798981;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #798981;
    }
}

.uk-textarea {
    height: auto;
}

.uk-form-label {
    font-family: $circe;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $secondary;
    margin-bottom: 10px;
    display: block;

    &-privacy {
        font-weight: normal;
    }
}

form .form-url {
    display: none;
}

.form-custom {
    background-color: $white;
    border-radius: 6px;
    padding: 60px 60px 60px 60px;
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.3);

}

.uk-form-group {
    margin-top: 30px !important;

    &:first-child {
        margin-top: 0 !important;
    }
}

select.custom-select {
    padding: 0px;
    height: 50px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $lines;
    font-family: $circe;
    background-color: transparent;
    font-size: 18px;
    line-height: 27px;
    color: $secondary;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: url(/dist/assets/images/icons/angle-down.svg) no-repeat 100% transparent;
    background-position: right 0px center;
    padding-right: 20px;

    &:active,
    &:focus {
        text-decoration: none;
        outline: none;
        border-bottom: 1px solid $primary;
    }
}

#skarabee-searchprofile {
    .btn {
        font-weight: bold;
        font-family: $dunbar;
        font-size: 14px !important;
        line-height: 21px !important;
        padding: 20px 40px;
        border-radius: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        transition: all .3s;
        letter-spacing: 2px;
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }

    .btn-primary {
        background-color: $primary !important;
        color: $secondary !important;
        border: none !important;

        &:hover {
            background-color: darken($color: $primary, $amount: 20%) !important;
            color: $secondary !important;
            cursor: pointer;
        }

        &:disabled {
            background-color: darken($color: $primary, $amount: 20%) !important;
            cursor: default;
        }
    }

    .btn-outline-primary {
        border: 1px solid $secondary !important;
        color: $secondary !important;
        background-color: transparent !important;

        &.selected {
            background-color: $secondary !important;
            color: $white !important;
        }
    }

    .estate-category {
        width: auto !important;
    }

    .estate-sort {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .dropdown-toggle {
        //     border: none !important;
        margin-right: auto;
        margin-left: auto;
        //     text-align: center;
        //     display: flex;
    }

    .dropdown-menu {
        display: flex;

        li {
            margin-right: 5px;

            &:last-of-type {
                margin-right: 0;
            }

            &:before {
                content: none;
            }
        }
    }

    .step {
        position: relative;
    }

    ngb-typeahead-window {
        top: 75px !important;
        left: 50% !important;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        transform: translate(-50%, 0) !important;

        button {
            margin-bottom: 5px;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .form-control {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .form-inline {
        border: none !important;
    }

    .dropdown-item {
        padding: 5px 10px;
        border: 1px solid $secondary;
        border-radius: 6px;
        background-color: transparent;

    }

}




// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
    .form-custom {
        padding: 30px;
    }
    .topfilter {
        select.custom-select {
            height: 30px;
        }
        .uk-accordion-content {
            margin-top: 10px;
        }
    }
}