// ===========================================================
//    _            __
//   (_)          / _|
//    _   _ __   | |_    ___
//   | | | '_ \  |  _|  / _ \
//   | | | | | | | |   | (_) |
//   |_| |_| |_| |_|    \___/
//
//   DIT IS ONZE EIGEN CUSTOM THEMING VOOR DE
//   WEBSITE, MET DEZELFDE STRUCTUUR ZOALS
//   VROEGER. (ENTRYPOINT VOOR GULP -> THEMES)
// ===========================================================

@import "bits",
"modules",
"layout",
"typography",
"coming-soon",
"404",
"other",
"cookie",
"utilities",
"queries";


/*------------------------------------*\
  #COMPONENTS SHOP
\*------------------------------------*/
@import 'components/shop/active-filter';
@import 'components/shop/add-to-cart';
@import 'components/shop/address-box';
@import 'components/shop/breadcrumb';
@import 'components/shop/buttons';
@import 'components/shop/cart-dropdown';
@import 'components/shop/cart';
@import 'components/shop/check-list';
@import 'components/shop/checkout-footer';
@import 'components/shop/checkout-nav';
@import 'components/shop/checkout-steps';
@import 'components/shop/color-picker';
@import 'components/shop/coupon';
@import 'components/shop/delivery';
@import 'components/shop/footer-bar';
@import 'components/shop/image-banner';
@import 'components/shop/info';
@import 'components/shop/link-sidebar';
@import 'components/shop/notification';
@import 'components/shop/order';
@import 'components/shop/pagination';
@import 'components/shop/payment-method';
@import 'components/shop/perk-bar';
@import 'components/shop/product';
@import 'components/shop/product-gallery';
@import 'components/shop/product-row';
@import 'components/shop/products-header';
@import 'components/shop/search-bar';
@import 'components/shop/sidebar';
@import 'components/shop/size-picker';
@import 'components/shop/table';


/*------------------------------------*\
  #COMPONENTS CMS
\*------------------------------------*/
@import 'components/cms/brand-grid';
@import 'components/cms/buttons';
@import 'components/cms/cta';
@import 'components/cms/default-dropdown-nav';
@import 'components/cms/footer';
@import 'components/cms/forms';
@import 'components/cms/home-banners';
@import 'components/cms/loader';
@import 'components/cms/main-navbar';
@import 'components/cms/mobile-menu';
@import 'components/cms/mobile-nav';
@import 'components/cms/mobile-slider-wrapper';
@import 'components/cms/newsletter';
@import 'components/cms/product-navbar';
@import 'components/cms/slider-button';
@import 'components/cms/top-banner';
@import 'components/cms/popup';
@import 'components/cms/top-bar';
@import 'components/cms/house-offer';
@import 'components/cms/blog';