.cta {
  background-color: $secondary;
  position: relative;

  p {
    color: $white !important;
    font-size: 18px;
  }

  h2 {
    color: $primary;
  }

  .card__image {
    background-position: right bottom !important;
    background-size: contain !important;
  }

  &--img-bttm {
    padding-bottom: 200px;

    .card__image {
      position: absolute;
      height: 200px;
      right: 0 !important;
      width: 100% !important;
      bottom: 0;
    }

    .pr-4 {
      padding-right: 0 !important;
    }
  }

  &--large {
    background-color: $secondary;
    padding: 60px;
  }

  &-triangle {
    position: absolute;
    bottom: 0;
    width: 42%;
    z-index: 3;

    &__left {
      left: 0;
    }

    &__right {
      right: 0;
    }

    img,
    svg {
      width: 100%;

      path {
        fill: $primary !important;
      }
    }
  }

  &-left {
    position: absolute;
    height: calc(100% + 200px);
    bottom: -100px;
    left: 50px;
    z-index: 5;

    img {
      height: 100%;
    }
  }

  &-right {
    position: absolute;
    height: calc(100% + 200px);
    bottom: -100px;
    right: 0px;
    z-index: 5;

    img {
      height: 100%;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
  .cta-right {
    bottom: -40px;
    height: 90%;

  }

  .cta-left {
    height: 90%;
    bottom: -40px;
    left: 0px;
  }
}

// Mobile devices
@media (max-width: 640px) {

  .cta-right,
  .cta-left {
    height: 80%;
  }
}