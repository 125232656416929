footer {
  background-color: $primary;
  padding: 75px 0px 35px;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 15px;

      a {
        font-weight: bold;
        font-family: $houschka;
        font-size: 18px;
        line-height: 21.6px;
        color: $secondary;
        transition: .2s;

        &:hover {
          color: $white;
          text-decoration: none;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  h2,
  .uk-h3 {
    color: $secondary;
  }

  hr {
    border-top: 1px solid $secondary;
    opacity: .16;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .footer-contact {
    margin-bottom: 20px;

    p,
    a {
      font-size: 16px;
      line-height: 24px;
    }

    a {
      font-weight: bold;
      transition: .2s;
      width: fit-content;

      &:hover {
        text-decoration: none;
        color: $white;
      }
    }
  }

  a,
  p,
  i {
    color: $secondary;
  }

  p {
    font-size: 14px;
    line-height: 21px;
  }

  .sub-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: $secondary;
      transition: .2s;

      &:hover {
        text-decoration: none;
        color: $white;
      }
    }

    i {
      font-size: 16px;
    }
  }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {}

// Mobile devices
@media (max-width: 640px) {
  footer {
    padding: 25px 0;

    .sub-footer {
      margin-top: 30px;
      flex-flow: column;
      align-items: flex-start;
    }
  }
}