.houses-offer {
    margin-top: 40px;

    &-item {
        &:nth-child(-1n+3) {
            margin-top: 0;
        }
    }

}

.house {
    &-image {
        margin-bottom: 40px;
        position: relative;
        background-color: $secondary;

        img {
            border-radius: 6px;
        }

        &__tag {
            position: absolute;
            background-color: $primary;
            color: $secondary;
            padding: 10px 20px;
            z-index: 2;
            border-radius: 6px 0 0 0;
            text-transform: uppercase;
            font-family: $dunbar;
            font-weight: bold;
            letter-spacing: 2px;
        }
    }

    &-data {
        &__title {}

        &__price {
            font-size: 14px;
            font-weight: bold;
            color: $primary;
        }

        &__intro {}

        &__details {}
    }

    &-big {
        margin-bottom: 60px;

        &:nth-last-child(-n + 2) {
            margin-bottom: 0;
        }
    }

    &-button {
        position: absolute;
        background-color: $primary;
        color: $secondary;
        padding: 21px 22px;
        border-radius: 50px;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        font-size: 16px;
        line-height: 0;
        opacity: 0;
        transition: .2s all ease-out;

    }

    &:hover {
        text-decoration: none;

        .house-button {
            top: 50%;
            opacity: 1;
            transition: .2s all ease-out;
        }
    }
}

.housedetail {
    &__price {
        font-size: 14px;
        font-weight: bold;
        color: $primary;
        font-family: $dunbar;
        text-transform: uppercase;
        letter-spacing: 2px;
    }

    &__status {
        background-color: $primary;
        color: $secondary;
        text-transform: uppercase;
        padding: 10px 20px;
        width: fit-content;
        font-family: $dunbar;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2px;
    }

    &__strengths {
        margin: 30px 0;

        span {
            margin-right: 15px;
            align-items: center;

            p {
                margin: 0;
            }

            i {
                color: $secondary;
            }

            img {
                height: fit-content;
                margin-right: 5px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__epc {
        clip-path: polygon(0% 0%, 70% 0, 100% 50%, 70% 100%, 0 100%);
        color: $white;
        text-transform: uppercase;
        font-family: $dunbar;
        font-size: 14px;
        font-weight: bold;
        padding: 10px 20px;
        min-height: 35px;
        height: 100%;

        &-success {
            background-color: $success-color;
        }

        &-good {
            background-color: $good-color;
        }
    }

    &__slider {
        // height: 100%;
        // top: 0;
        // left: 45%;
        // width: 55%;
        padding: 0;
        // overflow: hidden;

        .uk-slideshow-items,
        .uk-slideshow-items>li,
        .uk-slideshow-items>li>a,
        .uk-slideshow,
        .uk-slideshow>div {
            height: 100%;
        }

        &_previous,
        &_next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: $primary;
            width: 30px;
            height: 30px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                color: $secondary;
            }
        }

        &_previous {
            left: 20px;
        }

        &_next {
            right: 20px;
        }
    }

    &__checklist {
        margin-top: 30px;

        p {
            margin-top: 0;
        }

        span {
            border-bottom: 1px solid $lines;
            padding-bottom: 5px;
            margin-bottom: 15px;

            &:last-of-type {
                border-bottom: none;
            }
        }

        &-value {
            font-weight: bold;
        }
    }

    &__share {
        margin-top: 60px;
    }

    &__map {
        margin-bottom: 20px;
        height: 200px;

        &-location {
            font-weight: bold;
        }
    }

    &__cta {
        background-color: $primary;
        padding: 40px;
        margin-top: 60px;
        position: relative;

        span {

            i,
            a {
                font-weight: bold;
                color: $secondary;
            }
        }

        &-person {
            position: absolute;
            right: -14px;
            bottom: 0;
            height: 90%;

            img {
                height: 100%;
            }
        }
    }
}

// ==========[ NEW HOUSES ]==========
.newhouse-image {
    margin-bottom: 60px;
}



// ==========[ NEW HOUSES DETAIL ]==========
.uk-lightbox-items {
    li {
        &::before {
            content: none;
        }
    }
}

.gallery {
    ul {
        padding-left: 0;
        list-style: none;

        li::before {
            content: none !important;
        }
    }

    .projects {
        // overflow: hidden;
        transition: display .4s ease-out;
        margin-top: 0;
        margin-bottom: 30px;

        &-container {
            margin: 0;
            padding-bottom: 0;
        }
    }

    a {
        overflow: hidden;

        &:hover {
            text-decoration: none;

            .thumbnail {
                &-image {

                    transform: scale(1.1);
                }

                &-overlay {
                    &-background {
                        opacity: .5;
                    }

                    h2 {
                        opacity: 1;
                    }
                }
            }

        }
    }

    .thumbnail {
        overflow: hidden;
        transition: .4s;
        position: relative;
        background-color: transparent;

        &-image {
            transition: .4s;
            padding-bottom: 66.5%;
        }

        &-overlay {

            z-index: 5;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            h2 {
                z-index: 6;
                opacity: 0;
                transition: .3s;
            }

            &-background {
                background-color: $white;
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                transition: .4s;
            }
        }


    }

    &-image {
        padding-bottom: 56%;
    }

    &-video {
        display: flex;
        position: absolute;
        width: 100% !important;
        object-fit: cover;

        &-container {
            // padding-bottom: 56%;
            width: 100%;
            position: relative;

            &-big {
                margin: 0px auto;
                max-width: 1300px;

            }
        }
    }

    &-share {
        border-top: 1px solid $lines;
        margin-top: 44px;
        padding-top: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;

        .uk-button-tertiary {
            text-transform: none;
        }

        p {
            color: $secondary;
            font-weight: bold;
        }
    }
}


// ==========[ BREAKPOINTS ]==========

// Very large desktops
@media (max-width: 1600px) {}

// Desktops
@media (max-width: 1200px) {}

// Tablets
@media (max-width: 960px) {
    .house {
        &-image {
            margin-bottom: 20px;

            &__tag {
                font-size: 12px;
                letter-spacing: 1px;
                padding: 5px 10px;
            }
        }

        &-data__intro {
            margin-top: 10px;
        }

        &-data__price {
            margin-bottom: 0px;
        }

        &-big {
            margin-bottom: 40px;
        }

    }

    .houses-offer-item {
        margin-top: 40px !important;
    }

    .houses-offer-item:nth-child(-1n+2) {
        margin-top: 0px !important;
    }

    // .houses-offer-item:nth-child(-1n+3) {
    //     margin-top: 40px;
    // }

    .housedetail {
        &__cta {
            margin-top: 35px;
        }
    }

    .newhouse {
        &-image {
            margin-bottom: 35px;
        }

    }
}

// Mobile devices
@media (max-width: 640px) {
    .houses-offer-item:nth-child(-1n+3) {
        margin-top: 40px !important;
    }

    .houses-offer-item:nth-child(-1n+1) {
        margin-top: 0 !important;
    }
}